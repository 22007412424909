import http from "@/http/index";
import auth from "@/server/auth";

interface loginRequestDomain {
  username: string,
  password: string,
  grant_type: string
}

interface loginResponseDomain {
  access_token: string,
  expires_in: number
  account: {
    id: string,
    username: string,
    mobileNumber: string,
    type: string
  }
}

const api = {

  /**
   * 登录
   * @param data
   */
  login(data: loginRequestDomain): Promise<{data: loginResponseDomain}> {
    return http.post(auth.OAUTH_TOKEN, null, {
      params: data,
      headers: {
        Authorization: 'Basic TFJfeXhkOkxSX3l4ZA=='
      },
    });
  },

  // 刷新token
  refreshToken(query) {
    return http.post(`${auth.OAUTH_TOKEN}`, null, {
      params: query,
      headers: {
        Authorization: 'Basic TFJfeXhkOkxSX3l4ZA=='
      }
    });
  }
}

export {
  api,
  loginRequestDomain,
  loginResponseDomain
};
