import { createRouter, createWebHistory } from 'vue-router'
import {useStudentStore, useAxiosCancelToken} from "@/store";
import Storage from "@/utils/storage";
import {closeWindow, isWechat} from "@/utils/wxSDK";
import {wechatAuth} from "@/utils/user";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'ErrorPageSon',
      component: () => import('../views/exception/404.vue'),
      meta: {
        title: '404',
      },
    },
    {
      path: '/noWechat',
      name: 'noWechat',
      meta: {
        title: '请使用微信浏览器打开'
      },
      component: () => import('@/views/exception/no_wechat.vue')
    },
    {
      path: '/index',
      name: 'index',
      meta: {
        title: '智能学生证'
      },
      beforeEnter: (to, from) => {
        to.query.from = from.name as string;
      },
      component: () => import('../views/index.vue')
    },
    {
      path: '/mine',
      name: 'mine',
      meta: {
        title: '我的'
      },
      component: () => import('../views/mine/mine.vue')
    },
    {
      path: '/enclosure',
      name: 'enclosure',
      meta: {
        title: '围栏'
      },
      component: () => import('../views/enclosure/enclosure-list.vue')
    },
    {
      path: '/searchSite',
      name: 'searchSite',
      meta: {
        title: '搜索'
      },
      component: () => import('../views/enclosure/searchSite.vue')
    },
    {
      path: '/track',
      name: 'track',
      meta: {
        title: '轨迹'
      },
      component: () => import('../views/track/track-list.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      meta: {
        title: '设备管理'
      },
      component: () => import('../views/devices/devices-list.vue'),
    },
    {
      path: '/lessons',
      name: 'lessons',
      meta: {
        title: '课表管理'
      },
      component: () => import('../views/lessons/lessons-list.vue')
    },
    {
      path: '/clock',
      name: 'clock',
      meta: {
        title: '闹钟'
      },
      component: () => import('../views/clock/clock-list.vue')
    },
    {
      path: '/msglist',
      name: 'msglist',
      meta: {
        title: '代收短信'
      },
      component: () => import('../views/msglist/msg-list.vue'),
    },
    {
      path: '/feelist',
      name: 'feelist',
      meta: {
        title: '花费账单'
      },
      component: () => import('../views/feelist/fee-list.vue')
    },
    {
      path: '/templure',
      name: 'templure',
      meta: {
        title: '体温列表'
      },
      component: () => import('../views/templure/templure-list.vue')
    },
    {
      path: '/calledlist',
      name: 'calledlist',
      meta: {
        title: '通话记录'
      },
      component: () => import('../views/calledlist/called-list.vue')
    },
    {
      path: '/callWhitelist',
      name: 'callWhitelist',
      meta: {
        title: '通讯白名单'
      },
      component: () => import('../views/call-whitelist/callWhitelist.vue')
    },{
      path: '/growthRecord',
      name: 'growthRecord',
      meta: {
        title: '成长记录'
      },
      component: () => import('@/views/growth-record/growth-record.vue')
    },{
      path: '/studentEvaluationsList',
      name: 'studentEvaluationsList',
      meta: {
        title: '全部点评'
      },
      component: () => import('@/views/growth-record/student-evaluations-list.vue')
    },{
      path: '/notice',
      name: 'notice',
      meta: {
        title: '预警消息'
      },
      component: () => import('../views/notice/notice-list.vue')
    }
    ,{
      path: '/disabledtime',
      name: 'disabledtime',
      meta: {
        title: '禁用时间设置'
      },
      component: () => import('../views/disabledtime/disabledtime-list.vue')
    }
    ,{
      path: '/version',
      name: 'version',
      meta: {
        title: '版本介绍'
      },
      component: () => import('../views/version/version.vue')
    }
    ,{
      path: '/parentList',
      name: 'parentList',
      meta: {
        title: '家长用户管理'
      },
      component: () => import('../views/parent-list/parent-list.vue')
    },
    {
      path: '/studentInfo',
      name: 'studentInfo',
      meta: {
        title: '信息登记'
      },
      component: () => import('../views/student-info/student-info.vue')
    },
    {
      path: '/homeWork',
      name: 'homeWork',
      meta: {
        title: '我的作业'
      },
      component: () => import('../views/homeWork/homeWork.vue')
    },
    {
      path: '/guide',
      name: 'guide',
      meta: {
        title: '设备操作指南'
      },
      component: () => import('../views/guide/guide.vue')
    },
    {
      path: '/extracurricularContent',
      name: 'extracurricularContent',
      meta: {
        title: '课外内容'
      },
      component: () => import('../views/extracurricular-content/extracurricular-content.vue')
    },
    {
      path: '/extracurricularContentCatalog',
      name: 'extracurricularContentCatalog',
      meta: {
        title: '课外内容目录'
      },
      component: () => import('../views/extracurricular-content/extracurricular-content-catalog.vue')
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const StudentStore = useStudentStore();
  const axiosCancelToken = useAxiosCancelToken();

  // 前进后退
  window.onpopstate = function () {
    if (from.name == 'index') {
      closeWindow();
    }
  }
  // 生成环境下开启限制
  if (process.env.NODE_ENV == 'production') {
    if (!isWechat() && to.name != 'noWechat') {
      next({path: 'noWechat'});
    }
  }

  // 遍历取消请求
  axiosCancelToken.cancelArr.forEach(cancel => {
    cancel();
  });
  axiosCancelToken.cancelMap.forEach(cancel => cancel());
  axiosCancelToken.cancelArr = [];
  axiosCancelToken.cancelMap = new Map<string, any>();
  const currentStudent = Storage.getStudentList()[Storage.getCurrentIndex()]?.student;
  StudentStore.setCurrentStudent(currentStudent);
  // 获取不到当前学生信息，且目标路由不为 我的 || 首页 || 学生卡信息 || 班级作业 || 成长记录 || 家长管理
  // 跳转到首页
  if (!currentStudent && !['mine', 'index', 'studentInfo', 'homeWork', 'growthRecord', 'parentList'].includes((to.name) as string)) {
    next({
      path: 'index'
    });
  }
  // 有学生列表 且 学生未审核 且 目标路由不是我的 || 首页 强制跳转到首页
  else if (Storage.getStudentList()[Storage.getCurrentIndex()] && !Storage.getStudentList()[Storage.getCurrentIndex()].available && !['mine', 'index'].includes(to.name as string)){
    next({
      path: 'index'
    });
  } else {
    next();
  }
});


router.afterEach(() => {
  // 确保历史记录中没有旧的 hash
  const currentHash = window.location.hash;
  if (currentHash !== "#ProhibitReturn") {
    history.pushState({ page: 1 }, "title 1", "#ProhibitReturn");
  }
  // 移除默认的 hashchange 事件处理器，避免无限循环
  window.onhashchange = null;
});
export default router
