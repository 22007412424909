// /**
//  * https://www.weixinsxy.com/jssdk/
//  * 微信SDK
//  */
import wx from "weixin-js-sdk";
import {showToast} from 'vant';
import {api as WechatApi} from '@/api/wechat';

export const toStartScanner = function (successCallback) {
  wx.scanQRCode({ // 微信扫一扫接口
    desc: 'scanQRCode desc',
    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
    success: (res) => {
      const getCode = res.resultStr // 当needResult 为 1 时，扫码返回的结果
      successCallback(getCode);
    },
    fail: () => {
      showToast('微信js-sdk注入失败，请刷新页面重试');
    }
  })
}

// 打开相册选择或拍照
export const chooseImage = function (successCallBack) {
  wx.chooseImage({
    count: 1,// 默认9
    sizeType: ['original','compressed'],// 可以指定是原图还是压缩图，默认二者都有
    sourceType: ['album','camera'],// 可以指定来源是相册还是相机，默认二者都有
    success(res) {
      const localId = res.localIds[0];// 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
      wx.getLocalImgData({
        localId: localId, // 图片的localID
        success(res) {
          let localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示
          if (localData.indexOf('data:image') != 0) {
            localData = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '');
          }
          successCallBack(localData);
        },
      });
    },
  });
}

export const closeWindow = () => {
  wx.closeWindow();
}

//
//
// 获取当前url配置jssdk
export const getConfigUrl = function () {
  const u = window.navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  // 安卓需要使用当前URL进行微信API注册（即当场调用location.href.split('#')[0]）
  // iOS需要使用进入页面的初始URL进行注册，（即在任何pushstate发生前，调用location.href.split('#')[0]）
  let url: string;
  if (isiOS) {
    url = `${window.localStorage.getItem('_iosWXConfig_') || window.location.href.split('#')[0]}` //获取初始化的url相关参数
  } else {
    url = window.location.href.split('#')[0];
  }
  return url
}

/**
 * 基于目标页面注册传入apiList
 * @param toName
 */
export const baseToNameAfferentApiList = (toName) => {
  const pageUrl = getConfigUrl();
  switch (toName) {
    case 'index': {
      initWxConfigBefore(pageUrl, [
        'scanQRCode',
        'closeWindow'
      ]);
      return;
    }
    case 'mine':
    case 'devices': {
      initWxConfigBefore(pageUrl, [
        'scanQRCode',
      ]);
      return;
    }
    case 'studentInfo': {
      initWxConfigBefore(pageUrl, [
        'chooseImage',
        'getLocalImgData'
      ]);
      return;
    }
    default: {
      return;
    }
  }
}

/**
 * js_sdk
 */
export const initWxConfigBefore = function (pageUrl, jsApiList) {
  WechatApi.signature(pageUrl).then(res => {
    initWxConfig(res, jsApiList)
  }).catch(() => {
    showToast('数据异常，请刷新页面后重试！');
  });
}

const initWxConfig = (res, jsApiList) => {
  wx.config({
    appId: res.data.appId, // 必填，公众号的唯一标识
    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    signature: res.data.signature,// 必填，签名
    jsApiList // 必填，需要使用的 JS 接口列表
  });
  wx.ready(() => {
    console.log('ok')
  });

  wx.error((err) => {
    showToast('微信js-sdk注入失败，请刷新页面重试');
    console.log(err)
  });
}

// /**
//  * 判断是否微信浏览器
//  */
export function isWechat() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/micromessenger/i)) {
    console.log('微信浏览器')
    return true
  } else {
    console.log('普通浏览器,请在手机微信浏览器打开此页面')
    return false
  }
}
