import {defineStore} from "pinia";
import StudentDomain from "@/domain/student.domain";

export const useStudentStore = defineStore('currentStudent', {
  state: (): {
    currentStudent: StudentDomain | null,
    selectedWeekValues: Array<number>
  } => {
    return {
      currentStudent: null,
      selectedWeekValues: [1]
    }
  },

  getters: {
    getCurrentStudent(): StudentDomain | null {
      return this.currentStudent;
    },
    getSelectedWeekValues(): Array<number> {
      return this.selectedWeekValues;
    }
  },

  actions: {
    setCurrentStudent(student: StudentDomain | null) {
      this.currentStudent = student;
    },
    setSelectedWeekValues(selectedWeekValues: Array<number>) {
      console.log(selectedWeekValues)
      this.selectedWeekValues = selectedWeekValues;
    }
  },

  persist: {
    paths: ['selectedWeekValues']
  }

});

export const useAxiosCancelToken = defineStore('axiosCancelToken', {
  state: (): {
    cancelArr: Array<any>, // 用于存储所有请求
    cancelMap: Map<string, any>, // 用于存储会重复触发的请求
    cancelStudentInfoAvatarMap: Map<string, any>,
    cancelDownloadAttachmentMap: Map<string, any>, //用于存储正在下载附件的请求
  } => {
    return {
      cancelArr: [],
      cancelMap: new Map(),
      cancelStudentInfoAvatarMap: new Map(),
      cancelDownloadAttachmentMap: new Map()
    }
  },

  getters: {
    getCancelArr(): any {
      return this.cancelArr;
    }
  },

  actions: {
    checkCancel(url) {
      if (this.cancelMap.has(url)) {
        this.cancelMap.get(url)();
        this.cancelMap.delete(url);
      }
    }
  }
});

export const useKeepAlive = defineStore('keepAlive', {
  state: (): {
    exclude: Array<string>
  } => {
    return {
      exclude: []
    }
  },

  getters: {
    getExclude(): Array<string> {
      return this.exclude;
    }
  },

  actions: {
    pushExclude(value) {
      this.exclude.push(value);
    },
    deleteExclude(value) {
      this.exclude = this.exclude.filter(exclude => exclude != value);
    }
  }
})
