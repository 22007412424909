import {BaseUrlConstant} from "@/server/base-url.constant";

const url = {
  GET_GRADE_STUDENT_BY_CODE_NAME: `${BaseUrlConstant.EZLEARN_ACCOUNT_SERVICE}/student-accounts/mobile-number`,
  PARENT_BIND_STUDENT: `${BaseUrlConstant.EZLEARN_ACCOUNT_SERVICE}/student-parents/parent`,
  PARENT_WECHAT_LOGIN: `${BaseUrlConstant.EZLEARN_ACCOUNT_SERVICE}/parent-accounts/wechat/login`,
  STUDENT_PARENTS_STUDENT: `${BaseUrlConstant.EZLEARN_ACCOUNT_SERVICE}/student-parents/student`,
  STUDENT_PARENTS_ADMIN_PARENT: `${BaseUrlConstant.EZLEARN_ACCOUNT_SERVICE}/student-parents/admin-parent`,
  STUDENT_ACCOUNTS_STUDENT: `${BaseUrlConstant.EZLEARN_ACCOUNT_SERVICE}/student-accounts/student`
}

export default url;
