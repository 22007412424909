import http from "@/http/index";
import account from "@/server/account";
import StudentDomain from "@/domain/student.domain";
import ParentDomain from "@/domain/parent.domain";

interface getStudentRequestDomain {
  name: string,
  sex: string,
  enterYear: Date,
  parentName: string,
  mobileNumber: string,
  creator: string,
}

interface getGradeStudentByCodeAndNameResponseDomain {
  status: number,
  message: string,
  data: StudentDomain | string
}

export interface parentBindStudentRequestDomain {
  student: StudentDomain,
  creator: string
}

interface parentBindStudentResponseDomain {
  data: Array<bindStudentListDomain> | string,
  message: string,
  status: number
}

interface bindStudentListDomain {
  createTime: string,
  creator: string,
  id: string,
  parent: ParentDomain,
  student: StudentDomain,
  adminParent: 0 | 1,
  available: boolean
}

interface getParentBindStudentListResponseDomain {
  data: Array<bindStudentListDomain>,
  message: string,
  status: number
}

interface editStudentResponseDomain {
  data: StudentDomain | null,
  message: string,
  status: number
}

interface agreeAssociationStudentResponseDomain {
  data: bindStudentListDomain,
  status: number,
  message: string
}

const api = {
  // 根据班级码和学生姓名获取学生信息
  getStudent(data: getStudentRequestDomain, params): Promise<getGradeStudentByCodeAndNameResponseDomain> {
    return http.post(`${account.GET_GRADE_STUDENT_BY_CODE_NAME}/${data.mobileNumber}/modifyOrCreate`, data, {
      params
    });
  },

  // 家长绑定学生
  parentBindStudent(parentId: string, data: parentBindStudentRequestDomain): Promise<parentBindStudentResponseDomain> {
    return http.post(`${account.PARENT_BIND_STUDENT}/${parentId}`, data);
  },

  // 获取家长绑定学生列表
  getParentBindStudentList(parentId: string): Promise<getParentBindStudentListResponseDomain> {
    return http.get(`${account.PARENT_BIND_STUDENT}/${parentId}`);
  },

  // 家长取消绑定学生
  unBindStudent(parentId:string, data: bindStudentListDomain) {
    return http.delete(`${account.PARENT_BIND_STUDENT}/${parentId}`, {
      data
    });
  },

  // 获取用户信息
  parentAccountWechatLogin(data) {
    return http.post(account.PARENT_WECHAT_LOGIN, data);
  },

  // 获取学生用户绑定家长
  getStudentBindParentList(studentId: string) {
    return http.get(`${account.STUDENT_PARENTS_STUDENT}/${studentId}`);
  },

  // 解除家长绑定
  unBindStudentParent(parentId, bindStudent: bindStudentListDomain) {
    return http.delete(`${account.STUDENT_PARENTS_ADMIN_PARENT}/${parentId}`, {
      data: bindStudent
    });
  },

  // 编辑学生
  editStudent(studentId, parentId, data): Promise<editStudentResponseDomain> {
    return http.patch(`${account.STUDENT_ACCOUNTS_STUDENT}/${studentId}/parent/${parentId}`, data);
  },

  // 家长管理员审核家长关联学生
  agreeAssociationStudent(parentId: string, bindStudent: bindStudentListDomain): Promise<agreeAssociationStudentResponseDomain> {
    return http.patch(`${account.STUDENT_PARENTS_ADMIN_PARENT}/${parentId}`, bindStudent);
  }

}

export {
  bindStudentListDomain,
  api,
};
