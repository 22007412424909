// 需要加bearer token的地址
export const unNeedBearerAuthorizationUrls = [
  {
    method: 'POST',
    regex: '^/base-authentication-service/oauth/token$'
  },
  {
    method: 'GET',
    regex: '^/ezlearn-wechat-service/access-token$'
  },
  {
    method: 'GET',
    regex: '^/ezlearn-sms-service/sms/verification-codes/(.+)$'
  },
  {
    method: 'POST',
    regex: '^/ezlearn-account-service/parent-accounts/registration$'
  },
  {
    method: 'PATCH',
    regex: '^/ezlearn-account-service/accounts/mobile-number/(.+)/change-password$'
  },
  {
    method: 'POST',
    regex: '^/ezlearn-account-service/parent-accounts/wechat/login$'
  },
  {
    method: 'GET',
    regex: '^/ezlearn-push-service/clients$'
  }
]
