import {showToast} from "vant";
import {bindStudentListDomain} from "@/api/account";
import TrackDomain from "@/domain/track.domain";
import {reauthorization} from "@/utils/user";

const LocalStorage = {
  set(key: string, value: string): void {
    localStorage[key] = value;
  },

  get(key: string): string {
    return localStorage[key] || false;
  },

  setObject(key: string, value: object): void {
    localStorage[key] = JSON.stringify(value);
  },

  getObject(key: string): any {
    return JSON.parse(localStorage[key] || '{}');
  },

  remove(key: string): void {
    localStorage.removeItem(key);
  },

  clear(all: boolean, filter?: Array<StorageConstant>): void {
    let finalFilter: Array<StorageConstant> = [
      StorageConstant.REMEMBER_ACCOUNT,
      StorageConstant.READ_NEW_VERSION,
    ];
    if (all) {
      localStorage.clear();
    } else {
      if (filter) {
        finalFilter = [... new Set(finalFilter.concat(... filter))];
      }
      for (const storageKey in localStorage) {
        if (!finalFilter.includes(storageKey)) {
          localStorage.removeItem(storageKey);
        }
      }
    }
  }
}

export class StorageConstant {
  public static ACCESS_TOKEN = 'access_token';
  public static REFRESH_TOKEN = 'refresh_token';
  public static ACCOUNT = 'account';
  public static STUDENT_LIST = 'student_list';
  public static CURRENT_STUDENT_INDEX = 'current_student_index';
  public static GEOLOCATION = 'geolocation';
  public static REMEMBER_ACCOUNT = 'remember_account';
  public static READ_NEW_VERSION = 'read_new_version';
  public static STUDENT_GEOLOCATION = 'student_geolocation';
  public static STUDENT_LOCATION_INFO = 'student_location_info';
  public static PAGE_POPOVER_STATUS = 'page_popover_status';
  public static HAND_CLOSE_BIND_DEVICE_CONFIRM = 'hand_close_bind_device_confirm';
}

const Storage = {
  // 设置accessToken
  setAccessToken(access_token: accessTokenDomain) {
    LocalStorage.setObject(StorageConstant.ACCESS_TOKEN, access_token);
  },

  // 获取accessToken
  getAccessToken(): accessTokenDomain | object {
    const accessToken = LocalStorage.getObject(StorageConstant.ACCESS_TOKEN);
    if (accessToken && JSON.stringify(accessToken) != '{}') {
      // token有效时间不足5分钟，返回空对象，提醒重新获取token
      if (((Date.now() - accessToken.saveTime) / 1000) >= (accessToken.expires_in - 300)) {
        return {};
      } else {
        return accessToken;
      }
    } else {
      return accessToken;
    }
  },

  // 设置refreshToken
  setRefreshToken(refreshToken) {
    LocalStorage.set(StorageConstant.REFRESH_TOKEN, refreshToken);
  },

  // 获取refreshToken
  getRefreshToken() {
    return LocalStorage.get(StorageConstant.REFRESH_TOKEN);
  },

  // 设置绑定学生列表
  setStudentList(studentList: Array<bindStudentListDomain>) {
    LocalStorage.setObject(StorageConstant.STUDENT_LIST, studentList);
  },

  // 获取绑定学生列表
  getStudentList(): Array<bindStudentListDomain> {
    const studentList = LocalStorage.getObject(StorageConstant.STUDENT_LIST);
    if (studentList && JSON.stringify(studentList) == '{}') return [];
    return studentList;
  },

  // 设置当前学生下标
  // 页面被刷新，pinia中当前学生被清除可通过该数据获取当前学生
  setCurrentIndex(currentIndex: number) {
    LocalStorage.set(StorageConstant.CURRENT_STUDENT_INDEX, String(currentIndex));
  },

  // 设置当前学生下标
  getCurrentIndex(): number {
    const currentStudentIndex = LocalStorage.get(StorageConstant.CURRENT_STUDENT_INDEX);
    if (currentStudentIndex) {
      const studentLength = Storage.getStudentList().length;
      if (studentLength == 0) return 0;
      const maxIndex = studentLength - 1;
      if (Number(currentStudentIndex) > maxIndex) return maxIndex;
      return Number(currentStudentIndex);
    } else {
      return 0;
    }
  },

  // 设置用户信息
  setAccount(account: accountDomain) {
    LocalStorage.setObject(StorageConstant.ACCOUNT, account);
  },

  // 获取用户信息
  getAccount(): accountDomain {
    const account: accountDomain = LocalStorage.getObject(StorageConstant.ACCOUNT);
    if (account && JSON.stringify(account) == '{}') {
      showToast('用户信息过期，请重新授权!');
      reauthorization();
    }
    return account;
  },

  // 存储账号，用于登录界面自动写入
  setRememberAccount(rememberAccount) {
    LocalStorage.setObject(StorageConstant.REMEMBER_ACCOUNT, rememberAccount);
  },

  getRememberAccount() {
    return LocalStorage.getObject(StorageConstant.REMEMBER_ACCOUNT);
  },

  // 设置手机定位
  setGeoLocation(geoLocation) {
    LocalStorage.setObject(StorageConstant.GEOLOCATION, geoLocation);
  },

  // 获取手机定位
  getGeoLocation() {
    return LocalStorage.getObject(StorageConstant.GEOLOCATION);
  },

  // 存储学生位置
  setStudentGeoLocation(geoLocation) {
    LocalStorage.setObject(StorageConstant.STUDENT_GEOLOCATION, geoLocation);
  },

  // 获取学生位置
  getStudentGeoLocation() {
    return  LocalStorage.getObject(StorageConstant.STUDENT_GEOLOCATION);
  },

  // 设置学生位置信息
  setStudentLocationInfo(info: TrackDomain) {
    LocalStorage.setObject(StorageConstant.STUDENT_LOCATION_INFO, info);
  },

  // 获取学生位置信息
  getStudentLocationInfo(): TrackDomain {
    return LocalStorage.getObject(StorageConstant.STUDENT_LOCATION_INFO);
  },

  // 设置已阅读版本号
  setReadNewVersion(version) {
    LocalStorage.set(StorageConstant.READ_NEW_VERSION, version);
  },

  // 获取已阅读版本号
  getReadNewVersion(): string {
    return LocalStorage.get(StorageConstant.READ_NEW_VERSION);
  },

  getPagePopoverStatus() {
    return LocalStorage.getObject(StorageConstant.PAGE_POPOVER_STATUS);
  },

  setPagePopoverStatus(status) {
    LocalStorage.setObject(StorageConstant.PAGE_POPOVER_STATUS, Object.assign(this.getPagePopoverStatus(), status));
  },

  // 获取是否已经手动关闭过绑定设备提醒框
  getHandCloseBindDeviceConfirm() {
    return LocalStorage.get(StorageConstant.HAND_CLOSE_BIND_DEVICE_CONFIRM);
  },

  setHandCloseBindDeviceConfirm(handCloseBindDeviceConfirm: any) {
    LocalStorage.set(StorageConstant.HAND_CLOSE_BIND_DEVICE_CONFIRM, handCloseBindDeviceConfirm)
  },

  /**
   * 清除localStorage
   * @param all 是否全部清除
   * @param filter 过滤清除项
   */
  clear(all = true, filter?: Array<StorageConstant>) {
    LocalStorage.clear(all, filter);
  }
}

export interface accessTokenDomain {
  access_token: string,
  expires_in: number,
  saveTime: number
}

export interface accountDomain {
  username: string,
  mobileNumber: string,
  id: string,
  name: string,
}

export default Storage;

