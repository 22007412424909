import axios from 'axios';
import {unNeedBearerAuthorizationUrls} from "@/server";
import Storage, {accessTokenDomain} from "@/utils/storage";
import {wechatAuth, reauthorization, reLogin} from "@/utils/user";
import {useAxiosCancelToken} from "@/store";
import {showToast} from "vant";


const http = axios.create({
  baseURL: '/api',
  timeout: 120000,
});

const wsHttp = axios.create({
  baseURL: '/ws',
  timeout: 120000,
});

// 请求拦截器
http.interceptors.request.use(async config => {
  const axiosCancelToken = useAxiosCancelToken();
  // 存入取消请求数组，切换路由时遍历取消请求
  if (!config.cancelToken) {
    config.cancelToken = new axios.CancelToken((c) => {
      axiosCancelToken.cancelArr.push(c);
    });
  }
  const configs = await setBearer(config);
  if (configs) return configs;
}, error => {
  return Promise.reject(error);
});

wsHttp.interceptors.request.use(async config => {
  const axiosCancelToken = useAxiosCancelToken();
  // 存入取消请求数组，切换路由时遍历取消请求
  if (!config.cancelToken) {
    config.cancelToken = new axios.CancelToken((c) => {
      axiosCancelToken.cancelArr.push(c);
    });
  }
  const configs = await setBearer(config);
  if (configs) return configs;
}, error => {
  return Promise.reject(error);
});

const setBearer = async (config) => {
  const unNeedBearer = unNeedBearerAuthorizationUrls.find(urlRegex => {
    if (urlRegex.method == config.method.toUpperCase() && urlRegex.regex) {
      return new RegExp(urlRegex.regex).test((config.url) as string);
    }
  });
  // 不需要携带token，直接返回
  if (unNeedBearer) {
    return config;
  }
  // 需要携带token
  const accessToken = Storage.getAccessToken();
  // 获取到token， token距离过期时间大于5分钟，直接赋值
  if (accessToken && JSON.stringify(accessToken) != '{}') {
    config.headers.Authorization = `bearer ${(<accessTokenDomain>accessToken).access_token}`;
  }
  // token有效时间不足5分钟，刷新
  else {
    // 是否成功重新登录获取token
    const newAccessToken: any = await reLogin();
    // 成功获取新token，赋值
    if (newAccessToken) {
      config.headers.Authorization = `bearer ${newAccessToken.access_token}`;
    }
    // 获取不到新token，跳转到登录页面
    else {
      showToast('认证已过期，请重新授权！');
      reauthorization();
      wechatAuth();
    }
  }
  return config
}

// 响应拦截器
http.interceptors.response.use(value => {
  return value.data;
}, error => {
  // 处理 HTTP 网络错误
  let message = "";
  // HTTP 状态码
  const status = error.response?.status;
  switch (status) {
    case 401:
      message = "token失效，请重新登录";
      reauthorization();
      wechatAuth();
      break;
    case 403:
      message = "没有权限,请获取权限后登录";
      break;
    case 404:
      message = "页面不存在";
      break;
    case 500:
      message = "服务器故障";
      break;
    case 502:
      message = "数据库查询错误";
      break;
    default:
      message = "网络连接错误";
  }
  return Promise.reject({
    message,
    error
  });
});

wsHttp.interceptors.response.use(value => {
  return value.data;
}, error => {
  // 处理 HTTP 网络错误
  let message = "";
  // HTTP 状态码
  const status = error.response?.status;
  switch (status) {
    case 401:
      message = "token失效，请重新登录";
      reauthorization();
      wechatAuth();
      break;
    case 403:
      message = "没有权限,请获取权限后登录";
      break;
    case 404:
      message = "页面不存在";
      break;
    case 500:
      message = "服务器故障";
      break;
    case 502:
      message = "数据库查询错误";
      break;
    default:
      message = "网络连接错误";
  }
  return Promise.reject({
    message,
    error
  });
});


export default http;
export {
  wsHttp
}

