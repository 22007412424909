export class BaseUrlConstant {
  public static BASE_AUTHENTICATION_SERVICE = '/base-authentication-service';
  public static EZLEARN_WECHAT_BASE_SERVICE = '/ezlearn-wechat-service';
  public static EZLEARN_STUDENT_CARD_SERVICE = '/ezlearn-studentCard-service';
  public static EZLEARN_STUDENT_SMS_SERVICE = '/ezlearn-sms-service';
  public static EZLEARN_ACCOUNT_SERVICE = '/ezlearn-account-service';
  public static EZLEARN_ORGANIZATION_SERVICE = '/ezlearn-organization-service';
  public static EZLEARN_ATTACHMENT_SERVICE = '/ezlearn-attachment-service';
  public static EZLEARN_TEACHING_SERVICE = '/ezlearn-teaching-service';
  public static EZLEARN_PUSH_SERVICE = '/ezlearn-push-service';
}
