import http from "@/http";
import wechat from '@/server/wechat'

const api = {
  signature(configUrl) {
    return http.get(wechat.SIGANTURE, {
      params: {
        url: configUrl
      }
    });
  },
  // 根据code获取access_token openid
  access_token(params) {
    return http.get(wechat.GET_ACCESS_TOKEN_BY_CODE, {
      params
    });
  }
}

export {
  api
}
