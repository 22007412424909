import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from "pinia";
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import VueWechatTitle from 'vue-wechat-title'
import '@/assets/iconfont/iconfont.css';
import '@amap/amap-jsapi-types';
const app = createApp(App);
app.config.globalProperties.$colors = {
  themeColor: '#ff7222'
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

app.use(VueWechatTitle);
app.use(router);
// app.use(wx)
app.use(pinia);

app.mount('#app');
